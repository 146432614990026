<script setup lang="ts">
// COMPOSABLES
const colorMode = useColorMode();
const isOnline = useOnline();
const { notifyWarning, notifySuccess } = useNotification();

// DATA
const color = computed(() => (colorMode.value === 'dark' ? '#111827' : 'white'));

useHead({
    titleTemplate: title => {
        return title ? `${title} - Fleequid Admin` : 'Fleequid Admin';
    },
    meta: [{ key: 'theme-color', name: 'theme-color', content: color }],
});

// WATCHER
watch(isOnline, (value, oldValue) => {
    if (value === oldValue) {
        return;
    }

    if (value) {
        notifySuccess({ title: "You're back online!" });
    } else {
        notifyWarning({ title: 'You are offline', description: 'Some features may not work as expected!' });
    }
});
</script>

<template>
    <div>
        <NuxtPwaManifest />
        <NuxtLoadingIndicator
            color="repeating-linear-gradient(to right,rgb(var(--color-primary-200)) 0%,rgb(var(--color-primary-500)) 50%,rgb(var(--color-primary-900)) 100%)"
        />

        <NuxtLayout>
            <NuxtPage />
        </NuxtLayout>

        <UNotifications />
        <UModals />
    </div>
</template>
